var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"stat-body p-1"},[_c('div',{staticClass:"mb-3",staticStyle:{"display":"flex"}},[_c('el-tooltip',{attrs:{"placement":"top-start","content":_vm.component.component_name}},[_c('p',{staticClass:"Title_sty mb-0 fw-300 pl-1",style:({ flexGrow: 1, color: _vm.font_color }),on:{"click":function($event){return _vm.openTableDialog()}}},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.name,_vm.hide_options ? 18 : 10, "..."))+" ")])]),(_vm.component.compare)?_c('div',{style:(`float: right;background-color: ${
          _vm.getComparedPercentage < 0 ? '#fbc4c4' : '#c2e7b0'
        }; border-radius : 7px; padding : 1px; margin-top : -10px; margin-right: -5px; font-size: 10px; font-weight : bold`)},[_c('el-tooltip',{attrs:{"content":'Previous ' +
            _vm.component.component_name +
            ': ' +
            _vm.component.compare_count,"placement":"top-start"}},[_c('span',[_vm._v(_vm._s((_vm.getComparedPercentage > 0 ? "+" : "") + _vm.getComparedPercentage)+"% "),_c('el-icon',{class:_vm.getComparedPercentage > 0
                  ? 'el-icon-caret-top'
                  : _vm.getComparedPercentage == 0
                  ? 'el-icon-check'
                  : 'el-icon-caret-bottom'})],1)])],1):_vm._e(),(!_vm.hide_options)?_c('div',{staticClass:"action_sty"},[_c('el-link',{staticStyle:{"margin-right":"5px"},attrs:{"icon":"el-icon-edit","underline":false},on:{"click":_vm.editStat}}),_c('el-link',{attrs:{"icon":"el-icon-delete","underline":false},on:{"click":_vm.deleteStat}})],1):_vm._e()],1),(_vm.isApplicationUser)?_c('div',{staticClass:"d-flex align-items-center"},[_c('icons',{staticClass:"iconStyle",attrs:{"iconName":_vm.component.icon ? _vm.component.icon : 'dashboard'}})],1):_c('div',{staticClass:"d-flex align-items-center"},[_c('icons',{staticClass:"iconStyle",attrs:{"iconName":_vm.component.icon}})],1),_c('div'),(_vm.isCurrencyStat)?_c('p',{staticClass:"number_c_sty count fw-300",style:({ color: _vm.font_color }),on:{"click":_vm.openTableDialog}},[_vm._v(" "+_vm._s(Intl.NumberFormat("en-US", { style: "currency", currency: _vm.component.currency_type ? _vm.component.currency_type : "USD", }).format(_vm.count))+" ")]):_c('p',{staticClass:"number_c_sty count fs-6 fw-300",style:({ color: _vm.font_color }),on:{"click":_vm.openTableDialog}},[_vm._v(" "+_vm._s(_vm.count)+" ")])]),_c('dialog-component',{attrs:{"title":_vm.component.component_name,"visible":_vm.tableVisible,"containerMinWidth":_vm.showFullTable ? '90vw' : 'auto',"containerHeight":_vm.showFullTable ? '100vh' : 'auto',"isShowFooter":false},on:{"before-close":_vm.closeDialog}},[_c('div',{style:({
        'max-height': _vm.maxHeight,
        'overflow-y': 'auto',
        'scrollbar-width': 'thin',
      })},[(_vm.tableVisible)?_c('CustomDashboardTable',{attrs:{"component":_vm.component,"entity_id":_vm.entity_id,"filter_id":_vm.filter_id,"customFilters":_vm.customFilters,"hide_options":true,"isFromApplicationuser":_vm.isApplicationUser,"hasViewPermission":_vm.hasViewPermission,"hasEditPermission":_vm.hasEditPermission}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }